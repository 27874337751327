'use client'; // Ensures this runs on the client side

import { AnalyticsBrowser } from '@segment/analytics-next';

const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || 'xx'; // Replace 'xx' with your default or remove it

const analytics = AnalyticsBrowser.load({
  writeKey: SEGMENT_WRITE_KEY,
});

export default analytics;