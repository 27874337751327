'use client'; // Ensures this runs on the client side

import {useSession} from "next-auth/react";
import {useEffect, useRef} from "react";
import {useAnalytics} from "@/context/AnalyticsContext";

export const AnalyticsAuthObserver: React.FC = () => {
    const analytics = useAnalytics();
    const {data: session, status} = useSession();
    const lastIdentification = useRef<string | null>(null);

    useEffect(() => {
        // Ensure that we only run the effect when the session check is complete
        if (status === "loading") {
            // console.log("Session is still loading, skipping effect...");
            return;
        }

        let identifyAs = null;
        if (status === 'authenticated' && session?.user){
            identifyAs = session.user.trackingId as string;
        }

        // console.log("Analytics auth observer", status, lastIdentification, ">", identifyAs, session)
        if (lastIdentification.current !== identifyAs) {
            if (identifyAs === null) {
                if (analytics) {
                    analytics.reset();
                }
                // console.log("analytics.reset() called")
            } else {
                if (analytics) {
                    analytics.identify(identifyAs);
                }
                // console.log("analytics.identify(identifyAs) called")
            }

            lastIdentification.current = identifyAs;
        }

    }, [status, session]);


    return null; // This component doesn't render anything
};