"use client"

import { cn } from "@/lib/utils"
import { useEffect, useRef, useState } from "react";
import { MessageSquareText, X, SendHorizontal } from "lucide-react";
import { Button } from "../ui/button";
import { freightRateRequest, FreightRateResponse } from "@/actions/chatbot/chatbot";
import { ResponseType } from "@/types/response-types";
import Image from "next/image";

export const ChatBotComponent = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

    const [chatMessages, setChatMessages] = useState<ChatMessageModel[]>([{
        isUser: false,
        response: {
            isError: false,
            message: `Welcome to the CM Navigator Freight Calculator! 
            
I'm here to assist you with [specific topic/service, e.g., answering questions, managing tasks, providing recommendations].
How can I help you today? Here are some examples of what you can ask me:
"[Example query 1]"
"[Example query 2]"
"[Example query 3]"

Feel free to ask anything or let me know how I can support you!`
        }
    }]);

    const handleNewUserMessage = (message: ChatMessageModel) => {
        setChatMessages((prevMessages) => [message, ...prevMessages]);

        // Simulate bot response with a loading message
        setIsWaitingForResponse(true);
        const loadingMessage: ChatMessageModel = {
            isUser: false,
            response: {
                isError: false,
                message: "...",
            }
        };
        setChatMessages((prevMessages) => [loadingMessage, ...prevMessages]);

        // Simulate API call delay
        // askForFreightRateMock();

        askForFreightRate({message: message.response.message});
    };


    const askForFreightRate = ({ message }: { message: string }) => {
        setIsWaitingForResponse(true);

        freightRateRequest(message)
            .then((response) => {
                if (response.status === ResponseType.ERROR) {
                    console.error(response.error);
                    handleFreightRateError(response.error);
                    return;
                }
                handleFreightRateSuccess(response.data);
            })
            .catch((error) => {
                console.error("failed getting frc: ", error)
                handleFreightRateError(error);
            })
            .finally(() => {
                setIsWaitingForResponse(false);
                
            })
    }

    const handleFreightRateSuccess = (freightResponse: FreightRateResponse) => {
        const response: ChatMessageModel = {
            isUser: false,
            response: freightResponse
        };

        removeLoadingMessage(response);
    }

    const handleFreightRateError = (error: string ) => {
        const response: ChatMessageModel = {
            isUser: false,
            response: {
                isError: true,
                message: error
            }
        };

        removeLoadingMessage(response)
    }

    const removeLoadingMessage = (response: ChatMessageModel | null) => {
        setChatMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const loadingIndex = updatedMessages.findIndex(msg => msg.response.message === "...");
            if (loadingIndex !== -1) {
                if (response) {
                    updatedMessages[loadingIndex] = response;
                } else {
                    return prevMessages.filter((msg, index) => index !== loadingIndex);
                }
            }
            return updatedMessages;
        });;
    }


    const askForFreightRateMock = () => {
        setIsWaitingForResponse(true);

        setTimeout(() => {
            handleFreightRateResponseMock();
            setIsWaitingForResponse(false);
        }, 2000);
    }

    const handleFreightRateResponseMock = () => {
        const mockResponse: ChatMessageModel = {
            isUser: false,
            response: {
                isError: false,
                message: `40.000 MTS Barley Stowage factor 50
Constanza / Antwerp
15000 MTS SHINC / 3600 MTS SHEX6
Jan 2025

Freight Indication: 24,30 USD/PMT`,
                price: 24.3
            }
        };

        const mockErrorResponse: ChatMessageModel = {
            isUser: false,
            response: {
                isError: true,
                message: "An error occurred while fetching the data"
            }
        };

        // Replace the loading message with the actual response
        setChatMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const loadingIndex = updatedMessages.findIndex(msg => msg.response.message === "...");
            if (loadingIndex !== -1) {
                updatedMessages[loadingIndex] = mockErrorResponse;
            }
            return updatedMessages;
        });
    };

    return (
        <div className={cn("fixed bottom-3 right-3 transition-all duration-300 rounded-t-2xl overflow-clip",
            isOpen ? "w-[400px] h-[632px] bg-neutral-900" : "w-[200px] h-[40px] bg-neutral-900"
        )}>
            {isOpen ? (
                <ChatWrapper
                    newMessageSubmitted={handleNewUserMessage}
                    isLoading={isWaitingForResponse}
                    closeClicked={() => setIsOpen(false)}
                >
                    {chatMessages.map((message, index) => (
                        <ChatResponseRenderer key={index} response={message.response} sentByUser={message.isUser} />
                    ))}
                </ChatWrapper>
            ) : (
                <div
                    className="flex justify-center items-center h-full cursor-pointer"
                    onClick={() => setIsOpen(true)}
                >
                    <span className="text-neutral-400">Freight Rate Chat</span>
                </div>
            )}
        </div>
    );
};

export const ChatResponseRenderer = ({ response, sentByUser }: { response: FreightRateResponse, sentByUser: boolean }) => {

    const { message, commodity, loadPort, dischargePort, quantity, price } = response;

    console.log(response);

    if (response.isError) {
        return <ErrorResponse message={message} />
    }

    if (sentByUser) {
        return <UserMessage message={message} />
    }

    
    if (!commodity && !loadPort && !dischargePort && !quantity && !price) {
        return (
            <TextResponse
                message={message}
                isLoading={message === "..."} />
        );
    }

    if (price) {
        return (
            <TextResponseWithQuote
                freightResponse={response} />
        );
    }

    // Rendering more detailed freight rate response
    return (
        <div className="flex flex-row justify-start items-end gap-2">
            <div className="w-6 h-6 aspect-square flex justify-center items-center rounded-full bg-neutral-800 text-[#A0A0A0]">
                <MessageSquareText size={12} />
            </div>
            <div className="flex flex-col items-start bg-[#2C2C2C] p-3 rounded-lg text-neutral-300">
                <p className="font-bold">{message}</p>
                {commodity && <p>Commodity: {commodity}</p>}
                {loadPort && <p>Load Port: {loadPort}</p>}
                {dischargePort && <p>Discharge Port: {dischargePort}</p>}
                {quantity && <p>Quantity: {quantity} tons</p>}
                {price && <p>Price: ${price}</p>}
            </div>
        </div>
    );
};


export const ChatWrapper = ({ children, newMessageSubmitted, isLoading = false, closeClicked }: {
    children: any,
    newMessageSubmitted: (message: ChatMessageModel) => void,
    isLoading: boolean,
    closeClicked: () => void
}) => {
    const [newMessage, setNewMessage] = useState("");
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleSendMessage = (message: string) => {
        if (message.trim() !== "") {
            newMessageSubmitted({
                isUser: true,
                response: {
                    isError: false,
                    message: message,
                }
            });
            setNewMessage("");

            if (textAreaRef.current) {
                textAreaRef.current.style.height = "auto"; // Reset to original height
            }
        }
    };

    useEffect(() => {
        if (!isLoading && textAreaRef.current) {
            textAreaRef.current.focus();
        }
    }, [isLoading]);

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewMessage(e.target.value);
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    };

    return (
        <div className="w-full h-full flex flex-col">
            <div className="w-full h-[56px] bg-[#2E2E2E] justify-between items-center flex flex-row p-4">
                <div className="flex flex-row items-center gap-4">
                    <div className="h-10 w-10 flex items-center justify-center bg-primary-200 rounded-full">
                    <Image 
                        width={24}
                        height={24}
                        src="/cmn_logo_2023.svg"
                        alt="CM Navigator Logo"
                    />
                    </div>
                    <span className="text-neutral-300 font-roboto font-light">Freight Rate Chat</span>
                </div>
                <button
                    onClick={closeClicked}
                    className="text-[#A0A0A0] hover:text-neutral-200">
                    <X />
                </button>
            </div>
            <div className="flex flex-col-reverse w-full h-full overflow-y-scroll m-0 pt-4 pb-4 px-4 gap-4 bg-neutral-900">
                {children}
            </div>
            <div className="flex flex-row items-end bg-neutral-800 p-2 gap-2">
                <textarea
                    ref={textAreaRef}
                    disabled={isLoading}
                    className="w-full bg-neutral-900 text-neutral-300 placeholder:text-neutral-500 rounded-md border-0 focus-visible:border-0 focus-visible:ring-0 focus-within:shadow-none p-2 resize-none overflow-hidden"
                    onChange={handleTextAreaChange}
                    value={newMessage}
                    placeholder="Start Typing..."
                    rows={1}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage(newMessage);
                        }
                    }}
                />
                <button
                    className="w-8 h-8 mb-1 text-[#A0A0A0] hover:text-neutral-200 transition-colors duration-200"
                    onClick={(e) => {
                        e.preventDefault();
                        handleSendMessage(newMessage);
                    }}>
                    <SendHorizontal size={24} />
                </button>
            </div>
        </div>
    );
};

type ChatMessageModel = {
    isUser: boolean;
    response: FreightRateResponse;
}




export const UserMessage = ({ message }: { message: string }) => {
    return (
        <div className={cn("flex text-left max-w-[80%] font-roboto font-light text-[#E0E0E0] rounded-tl-2xl rounded-tr-2xl rounded-bl-2xl p-3 self-end bg-[#624BAD] whitespace-pre-wrap")}>
            {message}
        </div>
    );
}

export const ErrorResponse = ({ message }: { message: string }) => {
    return (
        <div className="flex flex-row justify-start items-end gap-2">
            <div className="w-6 h-6 aspect-square flex justify-center items-center rounded-full bg-[#E74311] text-neutral-900">
                <Image 
                    width={12}
                    height={12}
                    src="/cmn_logo_2023.svg"
                    alt="CM Navigator Logo"
                />

            </div>
            <div className="flex text-left max-w-[80%] text-[#E0E0E0] rounded-tl-2xl rounded-tr-2xl rounded-br-2xl p-3 self-start bg-[#2C2C2C] border-[#E74311] border whitespace-pre-wrap">
                <p>
                    <span className="font-bold text-[#E74311]">Error: </span>
                    {message}
                </p>
            </div>
        </div>
    );
}

export const TextResponse = ({ message, isLoading = false }: { message: string, isLoading?: boolean }) => {
    return (
        <div className="flex flex-row justify-start items-end gap-2">
            <div className="w-6 h-6 aspect-square flex justify-center items-center rounded-full bg-primary-200 text-[#A0A0A0]">
                <Image 
                    width={12}
                    height={12}
                    src="/cmn_logo_2023.svg"
                    alt="CM Navigator Logo"
                />
            </div>
            <div className={cn("flex text-left max-w-[80%] text-[#E0E0E0] rounded-tl-2xl rounded-tr-2xl rounded-br-2xl p-3 self-start bg-[#2C2C2C] whitespace-pre-wrap")}>
                {isLoading ? <LoadingAnimation /> : message}
            </div>
        </div>
    );
}

export const TextResponseWithQuote = ({ freightResponse, isLoading = false }: { freightResponse: FreightRateResponse, isLoading?: boolean }) => {
    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row justify-start items-end gap-2">
                <div className="w-6 h-6 aspect-square flex justify-center items-center rounded-full bg-primary-200 text-[#A0A0A0]">
                    <Image 
                        width={12}
                        height={12}
                        src="/cmn_logo_2023.svg"
                        alt="CM Navigator Logo"
                    />
                </div>
                <div className={cn("flex flex-col text-left max-w-[80%] text-[#E0E0E0] rounded-tl-2xl rounded-tr-2xl rounded-br-2xl p-3 self-start bg-[#2C2C2C] whitespace-pre-wrap")}>
                    {isLoading ? <LoadingAnimation /> : (
                        <>
                            <p>{freightResponse.message}</p>
                            <p className="text-neutral-400 text-sm pt-4">Freight Indication:</p>                    
                            <h2 className="font-recoleta text-2xl ">{freightResponse.price?.toFixed(2)} USD/PMT</h2>
                        </>
                    )}

                </div>
            </div>
            <div className="flex flex-row justify-start items-end gap-2">
                <div className="w-6 h-6 aspect-square">
                </div>
                <button
                    className="w-full max-w-[80%] h-8 flex justify-center items-center self-start bg-[#E2F6B9] hover:opacity-80 rounded-full text-[#121212]"
                >
                    Get a Quote 
                </button>
            </div>

        </div>
    )
}


const LoadingAnimation = () => {
    return (
        <div className="flex items-center h-6">
            <div className="w-2 h-2 bg-[#E0E0E0] rounded-full animate-bounce mr-1"></div>
            <div className="w-2 h-2 bg-[#E0E0E0] rounded-full animate-bounce mr-1" style={{ animationDelay: '0.2s' }}></div>
            <div className="w-2 h-2 bg-[#E0E0E0] rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
        </div>
    );
};
