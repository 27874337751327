'use client';

import React, { createContext, useContext, ReactNode } from 'react';
import analytics from '../utils/analytics';
import { AnalyticsBrowser } from '@segment/analytics-next';

interface AnalyticsContextType {
  analytics: AnalyticsBrowser;
}

const AnalyticsContext = createContext<AnalyticsBrowser | null>(null);

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children }) => {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsBrowser | null => {
  const context = useContext(AnalyticsContext);
  // if (!context) {
  //   throw new Error('useAnalytics must be used within an AnalyticsProvider');
  // }
  // todo
  return context;
};
