import * as React from "react"

import {cn} from "@/lib/utils"
import {VariantProps, cva} from "class-variance-authority"

const paragraphVariants = cva(
    "leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
    {
        variants: {
            variant: {
                body1: "text-[22px] font-light font-roboto leading-[30px]",
                body2: "text-[16px] font-light font-roboto leading-[16px]",
                body3: "text-[12px] font-light font-roboto leading-[19px]",
            }
        },
        defaultVariants: {
            variant: "body1"
        }
    }
)

export interface ParagraphProps
    extends React.LabelHTMLAttributes<HTMLParagraphElement> {
}

const Paragraph = React.forwardRef<
    HTMLParagraphElement,
    ParagraphProps &
    VariantProps<typeof paragraphVariants>
>(
    ({className, variant, ...props}, ref) => {
        return (
            <p
                ref={ref}
                className={cn("text-wrap whitespace-break-spaces",
                    paragraphVariants({variant}), className)}
                {...props}>
            </p>
        )
    }
)
Paragraph.displayName = "Paragraph"

export {Paragraph}