"use server"

import { ActionResponse, ResponseType } from "@/types/response-types";

export type FreightRateResponse = {
    isError: boolean
    message: string;
    commodity?: string;
    loadPort?: string;
    dischargePort?: string;
    quantity?: number;
    tolerance?: number;
    price?: number;
}

export const freightRateRequest = async (message: string): Promise<ActionResponse<FreightRateResponse>> => {
    
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/plain");

    try {
        const response = await fetch("https://7f37-93-176-68-66.ngrok-free.app/text", {
            method: "POST",
            body: message,
            headers: myHeaders,
        });

        if (!response.ok) {
            console.log("Freight rate request failed:", response.status, response.statusText);
            
            // Handle the case where the response indicates a failure (e.g. 4xx or 5xx status code)
            return {
                status: ResponseType.ERROR,
                error: `Request failed with status ${response.status}: ${response.statusText}`
            };
        }

        console.log("response:", response);

        // Parse response as text (or JSON if needed)
        const responseData = await response.json();

        console.log("Freight rate response:", responseData);

        // Assuming that the response contains a 'calculations' array as per your example
        if (responseData.calculations && responseData.calculations.length > 0) {
            const calculation = responseData.calculations[0];

            console.log("Freight rate calculation:", calculation);
            // Mapping the data to FreightRateResponse
            const freightRateResponse: FreightRateResponse = {
                isError: false,
                message: responseData.message || "No observation available.",
                commodity: calculation.input.commodity,
                loadPort: calculation.input.load_port,
                dischargePort: calculation.input.discharge_port,
                quantity: calculation.input.amount,
                tolerance: calculation.input.tolerance,
                price: calculation.output.price, // Assuming price needs to be extracted from observation
            };

            console.log("Mapped Freight Rate Response:", freightRateResponse);

            return {
                status: ResponseType.SUCCESS,
                data: freightRateResponse,
            };
        } else {
            return {
                status: ResponseType.ERROR,
                error: responseData.message || "No calculations available.",
            };
        }
    } catch (error) {
        console.error("Error occurred while fetching freight rate:", error);
        return {
            status: ResponseType.ERROR,
            error: "An error occurred while fetching the data"
        };
    }
}

// // Helper function to extract price from the observation string
// const extractPriceFromObservation = (observation: string): number | undefined => {
//     const priceRegex = /(\d+(\.\d+)?)\s*USD\/PMT/;
//     const match = observation.match(priceRegex);
//     if (match) {
//         return parseFloat(match[1]);
//     }
//     return undefined;
// };